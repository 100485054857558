/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from 'js-cookie';
import moment from 'moment';
import { tz } from 'moment-timezone';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useGoogleLocationServices } from '@/components/LocationAutocompleteV2/hooks/useGoogleLocationServices';
import Search from '@/components/Search';
import {
  useGoogleMaps,
  useGoogleMapsApi,
  useItems,
  useOrganizationContext,
  useSanctuary,
} from '@/hooks';
import {
  ApplyInfo,
  GeoCodeProps,
  HandleUrlProps,
  Location,
  MapLocationInfo,
  QueryParams,
  SearchInfo,
  SortDirection,
  SortInfo,
  ViewInfo,
} from '@/hooks/use-items/types';
import { ItemsContext, useReservationEstimate } from '@/lib/context';
import { ACTIONS } from '@/lib/context/GoogleMaps/reducer/map_actions';
import { checkBrowserLocation, currentLocation } from '@/utils/location';

export type DeliveryPresentation = 'search' | 'results' | 'coming';

const FALLBACK_MIN_BOOKING_DAYS = 28;

const SearchPage = () => {
  const { data: sanctuaryData } = useSanctuary();

  const minBookingDays =
    sanctuaryData?.config?.minimumBookingDuration || FALLBACK_MIN_BOOKING_DAYS;

  const defaultStart = moment().startOf('hour').add(2, 'hour');
  const defaultEnd = moment()
    .startOf('hour')
    .add(2, 'hour')
    .add(minBookingDays, 'days');

  const {
    context: { org },
  } = useOrganizationContext();
  const { updateEstimate } = useReservationEstimate();

  const orgId = org?.organization?.id;

  const router = useRouter();
  const query = router.query as QueryParams;

  // honor query params if present on initial load
  const [isInitialSearch, setIsInitialSearch] = useState(
    Object.keys(query).length === 0
  );

  const typeQuery = query?.type;

  const { isGoogleLoaded, googleMapsLoadError } = useGoogleMapsApi();
  const { geocoder } = useGoogleLocationServices();

  const { dispatch } = useGoogleMaps();

  const startQuery = query?.start
    ? moment(decodeURIComponent(query?.start))
    : moment(new Date().toString());

  const endQuery = query?.end
    ? moment(decodeURIComponent(query?.end))
    : moment(new Date().toString());

  const locationQuery = query?.location;
  const fleetNumbersRaw = query?.['fleet-numbers'];
  const isMobileAndSpecificSearchOpen = query?.isMobileAndSpecificSearchOpen;

  const [fleetNumbers] = useState(
    fleetNumbersRaw ? fleetNumbersRaw.split(',') : []
  );

  const isInstantBook = query?.instabook
    ? query?.instabook === 'true'
    : undefined;

  const showEV = query?.['is-electric-vehicle']
    ? query?.['is-electric-vehicle'] === 'true'
    : undefined;

  let starter = defaultStart.clone();
  if (
    startQuery &&
    startQuery.isValid() &&
    !startQuery.isBefore(moment().add(1, 'hour').startOf('hour'))
  ) {
    starter = startQuery;
  }

  let ender = defaultEnd.clone();
  if (
    endQuery &&
    endQuery.isValid() &&
    !endQuery.isBefore(moment().add(1, 'hour'))
  ) {
    ender = endQuery;
  }

  const [searchInfo, setSearchInfo] = useState({
    start: starter,
    end: ender,
    type: typeQuery || '',
  });

  const currenttz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userOrServerLocation =
    Cookies.getJSON('userLocation') || Cookies.getJSON('serverLocation');

  const initialMapLocation = locationQuery
    ? { address: locationQuery }
    : userOrServerLocation;

  const [userLocation, setUserLocation] =
    useState<Location>(userOrServerLocation);

  const [mapLocation, setMapLocation] = useState<Location>(initialMapLocation);

  const [sortInfo, setSortInfo] = useState<SortInfo>({
    sortField: 'distance',
    sortDirection: SortDirection.ASC,
  });

  // CHECK ME - we may or may not need full `mapLocation` obj.
  useEffect(() => {
    if (mapLocation?.lat && mapLocation?.lng) {
      const { lat, lng } = mapLocation;
      dispatch({ type: ACTIONS.SET_LATLNG, payload: { lat, lng } });
    }
  }, [mapLocation?.lat, mapLocation?.lng, dispatch]);

  const [selectedItem, setSelectedItem] = useState(0);

  // NOTE: updates Search Views - vehicle detail, reservation
  const [detailView, setDetailView] = useState<ViewInfo>({
    detailViewId: null,
    type: '',
  });

  const [applyInfo, setApplyInfo] = useState<ApplyInfo>({
    isInstantBook,
    showEV,
  });

  const [locationBlocked, setLocationBlocked] = useState(false);

  const handleUrl = ({
    searchOptions,
    mapLocationOptions,
    filterOptions,
  }: HandleUrlProps) => {
    const urlParams = [];

    const start = searchOptions?.start;
    const end = searchOptions?.end;
    const type = searchOptions?.type;
    const instant = filterOptions?.isInstantBook;
    const electric = filterOptions?.showEV;
    const location = mapLocationOptions?.address;
    if (start && end) {
      const startTZ = tz(start, currenttz);
      const endTz = tz(end, currenttz);
      urlParams.push(
        `start=${encodeURIComponent(startTZ.format('MM/DD/YY hh:mm a z'))}`
      );
      urlParams.push(
        `end=${encodeURIComponent(endTz.format('MM/DD/YY hh:mm a z'))}`
      );
    }

    if (type) {
      urlParams.push(`type=${encodeURIComponent(type)}`);
    }

    if (location) {
      urlParams.push(`location=${encodeURIComponent(location)}`);
    }

    if (instant !== undefined) {
      urlParams.push(`instabook=${instant}`);
    }

    if (electric !== undefined) {
      urlParams.push(`is-electric-vehicle=${electric}`);
    }

    if (fleetNumbers && fleetNumbers.length > 0) {
      urlParams.push(
        `fleet-numbers=${encodeURIComponent(fleetNumbers.join(','))}`
      );
    }
    if (isMobileAndSpecificSearchOpen) {
      urlParams.push('isMobileAndSpecificSearchOpen=true');
    }

    // TODO - change to use `some` so it exits on the first diff it finds
    const hasChanged = urlParams.reduce((acc, str) => {
      const decode = decodeURIComponent(str);
      const [key, val] = decode.split('=');
      if (val !== router.query[key]) {
        return true;
      }
      return acc;
    }, false);

    if (hasChanged) {
      router.push(`/search?${urlParams.join('&')}`, undefined, {
        shallow: true,
      });
    }
  };

  useEffect(() => {
    handleUrl({
      searchOptions: searchInfo,
      mapLocationOptions: mapLocation,
      filterOptions: applyInfo,
    });
  }, [searchInfo, mapLocation, applyInfo]);

  const updateSearchInfo = (newInfo: SearchInfo) => {
    updateEstimate({
      'pick-up': newInfo.start.toISOString(),
      'drop-off': newInfo.end.toISOString(),
    });

    setSearchInfo(newInfo);
  };

  const updateUserLocation = (newLocation: Location) => {
    setUserLocation(newLocation);
  };

  const updateMapLocation = ({ ...newLocation }: MapLocationInfo) => {
    setMapLocation(newLocation);
  };

  const updateDetailView = (viewInfo: ViewInfo) => {
    setDetailView(viewInfo);
  };

  const updateSortInfo = (newSortInfo: SortInfo) => {
    setSortInfo(newSortInfo);
  };

  const updateApplyInfo = (newApplyInfo: ApplyInfo) => {
    setIsInitialSearch(false);
    handleUrl({
      searchOptions: searchInfo,
      mapLocationOptions: mapLocation,
      filterOptions: newApplyInfo,
    });

    setApplyInfo(newApplyInfo);
  };

  const updateLocationBlocked = (newPreference: boolean) => {
    setLocationBlocked(newPreference);
  };

  const updateSelectedItem = (id: number) => {
    if (!!id) {
      updateEstimate({ 'item-id': id });
    }
    setSelectedItem(id);
  };

  const geocode = ({ lat, lng, address = '' }: GeoCodeProps) => {
    const input = {
      ...(lat && lng ? { location: { lat, lng } } : {}),
      ...(address ? { address } : {}),
    };

    geocoder?.geocode(input, res => {
      const info = res && res[0];

      const geocodeLat = info?.geometry?.location?.lat();
      const geocodeLng = info?.geometry?.location?.lng();

      const newLocation = {
        lat: geocodeLat,
        lng: geocodeLng,
        address: info?.formatted_address,
      };

      updateUserLocation(newLocation);
      updateMapLocation(newLocation);
    });
  };

  // useEffect below fixes cannot update unmounted component bug
  useEffect(() => {
    let isMounted = true;

    if (!isGoogleLoaded || googleMapsLoadError || !geocoder) {
      return;
    }

    if (mapLocation?.address) {
      if (isMounted) {
        geocode({ address: mapLocation.address });
      }
    } else {
      checkBrowserLocation()
        .then(pos => {
          if (isMounted) {
            const { coords } = pos;
            const loc = {
              lat: coords.latitude,
              lng: coords.longitude,
              address: locationQuery,
            };
            Cookies.set('userLocation', loc);
            geocode({ ...loc });
          }
        })
        .catch(async () => {
          if (isMounted) {
            const loc = await currentLocation();
            geocode(loc);
          }
        });

      return () => {
        isMounted = false;
      };
    }
  }, [isGoogleLoaded, googleMapsLoadError, geocoder, mapLocation?.address]);

  const { data, isLoading } = useItems({
    isInitialSearch,
    orgId,
    lat: mapLocation?.lat,
    lng: mapLocation?.lng,
    isInstantBook,
    showEV,
    startTime: searchInfo.start.clone().utc().format('YYYY-MM-DD HH:mm:ss'),
    endTime: searchInfo.end.clone().utc().format('YYYY-MM-DD HH:mm:ss'),
    type: searchInfo.type,
    fleetNumbersURI: fleetNumbersRaw,
  });

  return (
    <ItemsContext.Provider
      value={{
        setIsInitialSearch,
        isLoading,
        items: data,
        searchInfo,
        updateSearchInfo,
        updateSortInfo,
        sortInfo,
        updateApplyInfo,
        applyInfo,
        updateLocationBlocked,
        detailView,
        updateDetailView,
        locationBlocked,
        fleetNumbers,
        selectedItem,
        updateSelectedItem,
        updateUserLocation,
        userLocation,
        updateMapLocation,
        mapLocation,
      }}
    >
      <Search />
    </ItemsContext.Provider>
  );
};

export default SearchPage;
